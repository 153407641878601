@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import '@radix-ui/themes/styles.css';

@font-face {
  font-family: 'Saans';
  src: url('fonts/Saans/Saans-Light-1.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Saans';
  src: url('fonts/Saans/Saans-Regular-1.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Saans';
  src: url('fonts/Saans/Saans-Medium-1.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Saans';
  src: url('fonts/Saans/Saans-SemiBold-1.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Saans';
  src: url('fonts/Saans/Saans-Bold-1.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

.radix-themes {
  --cursor-button: pointer;
  --cursor-checkbox: default;
  --cursor-disabled: not-allowed;
  --cursor-link: pointer;
  --cursor-menu-item: default;
  --cursor-radio: default;
  --cursor-slider-thumb: default;
  --cursor-slider-thumb-active: default;
  --cursor-switch: default;
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --border: 20 5.9% 90%;
    --foreground: 20 14.3% 4.1%;

    --muted: 60 4.8% 95.9%;
    --muted-foreground: 25 5.3% 44.7%;

    --popover: 0 0% 100%;
    --popover-foreground: 20 14.3% 4.1%;

    --card: 0 0% 100%;
    --card-foreground: 20 14.3% 4.1%;

    --input: 20 5.9% 90%;

    --primary: 24 9.8% 10%;
    --primary-foreground: 60 9.1% 97.8%;

    --secondary: 60 4.8% 95.9%;
    --secondary-foreground: 24 9.8% 10%;

    --accent: 48 17% 94%;
    --accent-foreground: 24 9.8% 10%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 60 9.1% 97.8%;
    --ring: 24 5.4% 63.9%;
    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}

::selection {
  background: #111111 !important;
  color: #ffffff !important;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.button-text {
  font-size: 15px;
  font-weight: 600;
}

.make-button {
  cursor: pointer;
  user-select: none;
}

.underline-on-hover {
  text-decoration: none;
}
.underline-on-hover:hover {
  text-decoration: underline;
}

.font-400 {
  font-weight: 400;
}
